.rs-alert-container .rs-alert-success .rs-alert-item-content {
  background-color: #10B981;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
}

.rs-alert-container .rs-alert-success .rs-alert-item-content .rs-icon {
  color: #ffffff;
}

.rs-alert-container .rs-alert-success .rs-alert-item-close {
  color: #ffffff;
}

.rs-alert-container .rs-alert-error .rs-alert-item-content {
  background-color: #EF4444;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
}

.rs-alert-container .rs-alert-error .rs-alert-item-close {
  color: #ffffff;
}


.rs-alert-container .rs-alert-error .rs-alert-item-content .rs-icon {
  color: #ffffff;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #4C51BF;
}

.rs-breadcrumb {
  margin: 0;
}

a {
  color: #4C51BF;
}

.rs-modal-body {
  padding-bottom: 0;
  overflow: hidden !important;
  max-height: max-content !important;
}

.rs-modal-scroll-mode {
  position: relative;
  margin-top: 30px;
  padding-bottom: 0px;
}

.rs-modal-open {
  overflow: auto;
}

.rs-drawer-body {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (max-height: 1000px) {
  .rs-picker-menu.rs-picker-check-menu {
    max-height: 15rem;
  }

  .rs-picker-menu.rs-picker-check-menu > div > div {
    max-height: 15rem;
  }
}


.rs-checkbox-checker span {
  font-size: 1.2rem;
}

audio {
  height: 4rem;
}


.rs-uploader-trigger input[type='file'] {
  width: 0px;
}
